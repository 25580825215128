import React, { useContext, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import HindiChannels from "./HindiChannels";
import { DropdownContext } from "./DropdownContext";

function NewsChannels() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dropdownContext = useContext(DropdownContext);

  if (!dropdownContext) {
    throw new Error("DropdownContext must be used within DropdownProvider");
  }

  const { selectedOption } = dropdownContext;

  return (
    <>
      <div className="Apps">
        <Helmet>
          <title>
            Watch Latest Hindi News Live: Aaj Tak, ABP News, Zee News & More
          </title>
          <meta
            name="description"
            content="Stay updated with the latest Hindi news, breaking stories, and top news in Hindi from Aaj Tak Live, ABP News Live, Zee News Live, and more. Get live updates on politics, business, and entertainment."
          />
          <meta
            name="keywords"
            content="latest Hindi news, today top news Hindi, latest news in Hindi today, breaking Hindi news, top Hindi news, latest Hindi news updates, Aaj Tak Live, ABP News Live, Zee News Live, India TV Live, News18 India Live, Republic Bharat Live, NDTV India Live, TV9 Bharatvarsh Live, Zee Business Live, CNBC Awaaz Live, ET Now Swadesh, News24 Live"
          />
        </Helmet>
        <Header />

        {selectedOption === "Hindi" && <HindiChannels />}

        <p style={{ display: "none" }}>
          Stay up-to-date with the Hindilatest Hindi newsHindi and breaking
          stories across India with live streaming from top Hindi news channels.
          Whether you're looking for Hinditoday's top news in HindiHindi, live
          coverage of significant events, or the latest developments in
          business, we've got you covered. Watch HindiAaj Tak LiveHindi,
          HindiABP News LiveHindi, HindiZee News LiveHindi, and more, directly
          from our website. Stay informed about national and international
          events, entertainment, politics, sports, and business news in
          real-time, all in Hindi.
        </p>

        <p style={{ display: "none" }}>
          Our website provides a one-stop destination for Hinditoday’s top news
          in HindiHindi, including live updates from channels like HindiIndia TV
          LiveHindi, HindiNews18 India LiveHindi, HindiRepublic Bharat
          LiveHindi, and HindiNDTV India LiveHindi. If you're interested in
          business and financial news, tune into HindiZee Business LiveHindi,
          HindiCNBC Awaaz LiveHindi, or HindiET Now SwadeshHindi for expert
          analysis, stock market trends, and the latest business updates in
          Hindi. Get real-time information and never miss an important news
          event again.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default NewsChannels;
