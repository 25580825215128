import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { cacheBuster } from "./config";
import { useDropdown } from "./DropdownContext";
import Modal from "react-modal";

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: "accepted" | "dismissed"; platform: string }>;
}

declare global {
  interface Window {
    deferredPrompt: BeforeInstallPromptEvent | null;
  }
}

window.deferredPrompt = null;

let deferredPrompt: any;
function Header() {
  const logo = `/assets/logo.png?v=${cacheBuster}`;
  const addtohomescreen = `/assets/add-to-home-screen.gif?v=${cacheBuster}`;

  const [installable, setInstallable] = useState(false);
  const [isIos, setIsIos] = useState(false);
  const [isInStandaloneMode, setIsInStandaloneMode] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isInIframe, setIsInIframe] = useState(false);
  const { selectedOption, setSelectedOption } = useDropdown();
  const [language, setLanguage] = useState<string>(selectedOption);

  useEffect(() => {
    // Function to handle messages from iframe
    const handleIframeMessage = (event: MessageEvent) => {
      if (event.data === "appInIframe") {
        console.log("Loaded inside an iframe.");
        setIsInIframe(true);
      } else if (event.data === "appDirect") {
        console.log("Loaded directly.");
        setIsInIframe(false);
      }
    };

    // Listen for messages
    window.addEventListener("message", handleIframeMessage);

    // Check loading context on mount
    if (window.self !== window.top) {
      // Loaded in an iframe
      setIsInIframe(true); // Set iframe state
      window.parent.postMessage("appInIframe", "*");
      console.log("App loaded inside an iframe.");
    } else {
      // Loaded directly
      setIsInIframe(false); // Set direct load state
      window.parent.postMessage("appDirect", "*");
      console.log("App loaded directly.");
    }

    return () => {
      window.removeEventListener("message", handleIframeMessage);
    };
  }, []);

  // Handle modal state based on selected option
  useEffect(() => {
    selectedOption === "Hindi" ? setModalIsOpen(false) : setModalIsOpen(true);
  }, [selectedOption]);

  // Load the saved language from localStorage on mount
  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    console.log("Retrieved language from localStorage:", savedLanguage);

    if (savedLanguage) {
      setLanguage(savedLanguage);
      setSelectedOption(savedLanguage);
      console.log("Selected language changed to:", savedLanguage);

      // Set isInIframe state based on the saved language
      if (window.self !== window.top) {
        setIsInIframe(true);
      } else {
        setIsInIframe(false);
      }
    }
  }, [setSelectedOption]);

  // Handle language change
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    setSelectedOption(newLanguage);

    // Notify about the iframe loading
    const iframeSrc = getIframeSrc(newLanguage);
    if (iframeSrc) {
      window.parent.postMessage("appInIframe", "*");
      console.log(`Loading ${newLanguage} app in iframe: ${iframeSrc}`);
      setIsInIframe(true); // Mark as in iframe
    } else {
      setIsInIframe(false); // Mark as direct if there's no src
    }

    // Save selected language to localStorage
    localStorage.setItem("selectedLanguage", newLanguage);
    console.log("Saved language to localStorage:", newLanguage);
  };

  // Function to get iframe source based on selected language
  const getIframeSrc = (language: string) => {
    switch (language) {
      case "English":
        return "https://english-news.live/";
      case "Bangla":
        return "https://bangla-news.live/";
      case "Gujarati":
        return "https://gujarati-news.in/";
      case "Odia":
        return "https://odia-news.in/";
      case "Malayalam":
        return "https://malayalamnews.live/";
      case "Kannada":
        return "https://kannada-news.in/";
      case "Tamil":
        return "https://tamil-news.in/";
      case "Telugu":
        return "https://telugu-news.in/";
      case "Marathi":
        return "https://marathi-news.in/";
      default:
        return "";
    }
  };

  useEffect(() => {
    // Detect if the device is iOS
    // const userAgent = window.navigator.userAgent.toLowerCase();
    const isIosDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);

    // Check if the app is already installed (standalone mode)
    const isStandalone =
      window.matchMedia("(display-mode: standalone)").matches ||
      (window.navigator as any).standalone === true;

    setIsIos(isIosDevice);
    setIsInStandaloneMode(isStandalone);

    // Handle the beforeinstallprompt event for non-iOS devices
    const handleBeforeInstallPrompt = (e: any) => {
      e.preventDefault();
      deferredPrompt = e;
      console.log(`'beforeinstallprompt' event was fired.`);
      setInstallable(true);
    };

    const handleAppInstalled = () => {
      console.log("INSTALL: Success");
    };

    // Register the event listeners for non-iOS devices
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    window.addEventListener("appinstalled", handleAppInstalled);

    return () => {
      // Cleanup event listeners on component unmount
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
      window.removeEventListener("appinstalled", handleAppInstalled);
    };
  }, []);

  const handleAddToHomeScreen = async () => {
    if (deferredPrompt) {
      // For non-iOS, show the install prompt
      setInstallable(false);
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === "accepted") {
        console.log("User accepted the install prompt");
      } else {
        console.log("User dismissed the install prompt");
      }
      deferredPrompt = null; // Clear the deferredPrompt after use
    }
  };

  const handleIosAddToHomeScreen = () => {
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  const imagesToPreload = [logo, addtohomescreen];

  // Preload the images
  imagesToPreload.forEach((src) => {
    const img = new Image();
    img.src = src;
  });

  let isAppInstalled = false;

  // Check whether app is installed or not on Chrome/Android
  window.addEventListener("beforeinstallprompt", (event: Event) => {
    const beforeInstallPromptEvent = event as BeforeInstallPromptEvent;
    beforeInstallPromptEvent.preventDefault();
    isAppInstalled = false;
    window.deferredPrompt = beforeInstallPromptEvent;
    console.log("App is not installed yet (Android/Chrome).");
  });

  // Check for installation status after a small delay
  setTimeout(() => {
    if (window.deferredPrompt) {
      isAppInstalled = false;
    } else {
      isAppInstalled = true;
    }
  }, 3000);

  // Additional condition to check if it's already installed without needing the prompt
  if (!window.deferredPrompt) {
    isAppInstalled = true;
  }

  return (
    <div className="header-container">
      <header
        className={
          modalIsOpen ? "Apps-header Apps-header-modal" : "Apps-header"
        }
      >
        <div className="logo">
          <div onClick={handleClick}>
            <img
              rel="preload"
              src={`${logo}?v=${cacheBuster}`}
              alt="Logo"
              width="50"
              height="50"
            />
          </div>
        </div>
        <div className="right-group">
          {!isInIframe && (
            <div className="language">
              <select
                className="lang-dropdown-desktop"
                value={language}
                onChange={handleChange}
              >
                <option value="" disabled selected>
                  Select Language
                </option>
                <option value="Hindi">Hindi</option>
                <option value="English">English</option>
                <option value="Malayalam">Malayalam</option>
                <option value="Kannada">Kannada</option>
                <option value="Marathi">Marathi</option>
                <option value="Tamil">Tamil</option>
                <option value="Telugu">Telugu</option>
                <option value="Gujarati">Gujarati</option>
                <option value="Bangla">Bangla</option>
                <option value="Odia">Odia</option>
              </select>
            </div>
          )}

          <div className="contact-email">
            <p>
              <i className="far fa-envelope"></i>
              <a href="mailto:contact@hindi-news.live">
                contact@hindi-news.live
              </a>
            </p>
            {(isInStandaloneMode || isAppInstalled) && !isInIframe && (
              <select
                className="lang-dropdown"
                value={language}
                onChange={handleChange}
              >
                <option value="" disabled selected>
                  Language
                </option>
                <option value="Hindi">Hindi</option>
                <option value="English">English</option>
                <option value="Malayalam">Malayalam</option>
                <option value="Kannada">Kannada</option>
                <option value="Marathi">Marathi</option>
                <option value="Tamil">Tamil</option>
                <option value="Telugu">Telugu</option>
                <option value="Gujarati">Gujarati</option>
                <option value="Bangla">Bangla</option>
                <option value="Odia">Odia</option>
              </select>
            )}

            {installable && !isIos && !isInStandaloneMode && (
              <img
                rel="preload"
                className="addtohomescreen"
                src={addtohomescreen}
                alt="Add to home screen"
                onClick={handleAddToHomeScreen}
              />
            )}
            {isIos && !isInStandaloneMode && !isAppInstalled && (
              <img
                rel="preload"
                className="addtohomescreen"
                src={addtohomescreen}
                alt="Add to IOS home screen"
                onClick={handleIosAddToHomeScreen}
              />
            )}
            {showMessage && (
              <div className="message">
                Click on the "Share" button, and select "Add to Home Screen"
              </div>
            )}
          </div>
        </div>
      </header>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          content: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            padding: 0,
            margin: 0,
            width: "100%",
            height: "100%",
            overflow: "hidden",
          },
        }}
      >
        <select
          value={language}
          onChange={handleChange}
          className="modal-dropdown"
        >
          <option value="" disabled selected>
            Language
          </option>
          <option value="Hindi">Hindi</option>
          <option value="English">English</option>
          <option value="Malayalam">Malayalam</option>
          <option value="Kannada">Kannada</option>
          <option value="Tamil">Tamil</option>
          <option value="Telugu">Telugu</option>
          <option value="Marathi">Marathi</option>
          <option value="Gujarati">Gujarati</option>
          <option value="Bangla">Bangla</option>
          <option value="Odia">Odia</option>
        </select>

        <iframe
          id="languageIframe"
          src={getIframeSrc(language)}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            overflow: "hidden",
          }}
          allow="autoplay; fullscreen"
          allowFullScreen
          title="News Channel"
        />
      </Modal>
    </div>
  );
}

export default Header;
